// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-us-js": () => import("./../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-index-js": () => import("./../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-the-uswl-js": () => import("./../../src/pages/join-the-uswl.js" /* webpackChunkName: "component---src-pages-join-the-uswl-js" */),
  "component---src-pages-league-office-js": () => import("./../../src/pages/league-office.js" /* webpackChunkName: "component---src-pages-league-office-js" */),
  "component---src-pages-legal-privacy-policy-js": () => import("./../../src/pages/legal/privacy-policy.js" /* webpackChunkName: "component---src-pages-legal-privacy-policy-js" */),
  "component---src-pages-legal-terms-of-use-js": () => import("./../../src/pages/legal/terms-of-use.js" /* webpackChunkName: "component---src-pages-legal-terms-of-use-js" */),
  "component---src-pages-schedule-js": () => import("./../../src/pages/schedule.js" /* webpackChunkName: "component---src-pages-schedule-js" */),
  "component---src-pages-stats-and-standings-js": () => import("./../../src/pages/stats-and-standings.js" /* webpackChunkName: "component---src-pages-stats-and-standings-js" */),
  "component---src-pages-teams-js": () => import("./../../src/pages/teams.js" /* webpackChunkName: "component---src-pages-teams-js" */),
  "component---src-pages-thank-you-js": () => import("./../../src/pages/thank-you.js" /* webpackChunkName: "component---src-pages-thank-you-js" */),
  "component---src-templates-news-list-js": () => import("./../../src/templates/news-list.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-news-post-js": () => import("./../../src/templates/news-post.js" /* webpackChunkName: "component---src-templates-news-post-js" */),
  "component---src-templates-single-team-page-js": () => import("./../../src/templates/single-team-page.js" /* webpackChunkName: "component---src-templates-single-team-page-js" */)
}

